<template>
  <div>
    <div class="search">
      <div class="title">定制列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="姓名:">
          <el-input v-model="params.trueName"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="params.status" placeholder="请选择">
            <el-option
              v-for="item in optionsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <!-- <el-table-column type="index" width="50" align="center">
      </el-table-column> -->
      <el-table-column prop="customId" label="ID" align="center">
      </el-table-column>
      <el-table-column prop="trueName" label="姓名" align="center">
      </el-table-column>

      <el-table-column prop="phone" label="手机号"  align="center">
      </el-table-column>
      <el-table-column prop="people" label="人数"  align="center">
      </el-table-column>
      <el-table-column prop="hotel" label="度假区"  align="center">
      </el-table-column>
      <el-table-column
        prop="addTime"
        label="提交时间"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="beginTime"
        label="出游时间"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="perFee" label="人均"  align="center">
      </el-table-column>
      <el-table-column prop="strStatus" label="状态"  align="center">
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="$router.push('/customInfoDetails?id='+scope.row.customId)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
        <el-form-item label="分类图片:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { customInfoList } from "../../api/travel.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        trueName: "",
        phone: "",
        beginTime: "",
        endTime: "",
        status: 0,
      },
      optionsList: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "定制中",
          value: 1,
        },
        {
          label: "已完成",
          value: 2,
        },
        {
          label: "已取消",
          value: 3,
        },
      ],
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      addRules: {
        tableData: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      this.params.beginTime = this.date ? this.date[0] : "";
      this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await customInfoList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>