import request from '../utils/request'
import baseUrl from './baseUrl'
export const travelCate = data =>
	request({
		url: baseUrl + '/travelCate/selectForBack',
		method: 'POST',
		data
	})
export const addTravelCate = data =>
	request({
		url: baseUrl + '/travelCate/add',
		method: 'POST',
		data
	})
export const editTravelCate = data =>
	request({
		url: baseUrl + '/travelCate/add',
		method: 'POST',
		data
	})
export const travelCateDetails = data =>
	request({
		url: baseUrl + '/travelCate/selectForId',
		method: 'POST',
		data
	})
export const delTravelCate = data =>
	request({
		url: baseUrl + '/travelCate/deleteById',
		method: 'POST',
		data
	})
export const changeSort = data =>
	request({
		url: baseUrl + '/travelCate/modifyDept',
		method: 'POST',
		data
	})
export const selectAllByFatherId = data =>
	request({
		url: baseUrl + '/travelCate/selectAllByFatherId',
		method: 'POST',
		data
	})
export const travelSpecsLog = data =>
	request({
		url: baseUrl + '/travelLog/selectForBack',
		method: 'POST',
		data
	})
export const travelLogDetails = data =>
	request({
		url: baseUrl + '/travelLog/selectForId',
		method: 'POST',
		data
	})
//上下架
export const changeStatus = data =>
	request({
		url: baseUrl + '/travelCate/upDownCate',
		method: 'POST',
		data
	})
//广告列表
export const adList = data =>
	request({
		url: baseUrl + '/travelCateAdv/selectForBack',
		method: 'POST',
		data
	})
//添加广告
export const addAd = data =>
	request({
		url: baseUrl + '/travelCateAdv/add',
		method: 'POST',
		data
	})
//查看广告详情
export const adDetail = data =>
	request({
		url: baseUrl + '/travelCateAdv/selectForId',
		method: 'POST',
		data
	})
//删除广告
export const delAd = data =>
	request({
		url: baseUrl + '/travelCateAdv/deleteById',
		method: 'POST',
		data
	})
//广告排序
export const adDept = data =>
	request({
		url: baseUrl + '/travelCateAdv/modify',
		method: 'POST',
		data
	})
//显示还是隐藏
export const showOrHide = data =>
	request({
		url: baseUrl + '/travelCateAdv/modifyStatus',
		method: 'POST',
		data
	})
export const tripList = data =>
	request({
		url: baseUrl + '/travelDay/selectForBack',
		method: 'POST',
		data
	})
export const tripListAdd = data =>
	request({
		url: baseUrl + '/travelDay/add',
		method: 'POST',
		data
	})
export const tripListDetails = data =>
	request({
		url: baseUrl + '/travelDay/selectForId',
		method: 'POST',
		data
	})
export const tripListSort = data =>
	request({
		url: baseUrl + '/travelDay/modifyDept',
		method: 'POST',
		data
	})
export const tripListDel = data =>
	request({
		url: baseUrl + '/travelDay/deleteById',
		method: 'POST',
		data
	})
export const scenicList = data =>
	request({
		url: baseUrl + '/travelScenic/selectForBack',
		method: 'POST',
		data
	})
export const scenicListAdd = data =>
	request({
		url: baseUrl + '/travelScenic/add',
		method: 'POST',
		data
	})
export const scenicListDetails = data =>
	request({
		url: baseUrl + '/travelScenic/selectForId',
		method: 'POST',
		data
	})
export const scenicListSort = data =>
	request({
		url: baseUrl + '/travelScenic/modify',
		method: 'POST',
		data
	})
export const scenicListDel = data =>
	request({
		url: baseUrl + '/travelScenic/deleteById',
		method: 'POST',
		data
	})
export const wanlvLineShow = data =>
	request({
		url: baseUrl + '/travel/modifyWlShow',
		method: 'POST',
		data
	})
export const customInfoList = data =>
	request({
		url: baseUrl + '/customInfo/selectForBack',
		method: 'POST',
		data
	})
export const customInfoDetails = data =>
	request({
		url: baseUrl + '/customInfo/selectForId',
		method: 'POST',
		data
	})
export const customInfoModify = data =>
	request({
		url: baseUrl + '/customInfo/modify',
		method: 'POST',
		data
	})
export const customInfoLog = data =>
	request({
		url: baseUrl + '/customLog/selectForBack',
		method: 'POST',
		data
	})
export const customInfoLogAdd = data =>
	request({
		url: baseUrl + '/customLog/add',
		method: 'POST',
		data
	})